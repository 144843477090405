import React, {Component} from "react"
import { Link } from "gatsby"
import Logo from "./shared/logo";
import Nav from "./shared/nav";
import cartIcon from "../assets/images/cart_icon.svg";
import searchIcon from "../assets/images/search_icon.svg";
import Search from "./shared/search";
import Menu from './menu';
import Greeting from "./greeting";

const API = process.env.GATSBY_API_URL;

class Header extends Component{

  constructor(props) {
    super(props);
    this.state = {
      account: [],
      isLoggedIn: false,
      showSearch: false
    };
  }

  componentDidMount() {
    fetch(API)
        .then(response => response.json())
        .then(data => {
          this.setState({
            account: data,
            isLoggedIn: data.hasOwnProperty('customer_id')
          })
        });
  }

  isLoggedIn(){
    return this.state.isLoggedIn;
  }

  toggleSearchForm(e){
    e.preventDefault();
    const newState = this.state;
    newState.showSearch = !newState.showSearch;
    this.setState(newState);
  }


  render() {
    const showSearch = this.state.showSearch;
    let theSearch;

    if ( showSearch ){
      theSearch = <div className="m9" id="collapseSearch">
        <Search/>
      </div>;
    }

    return <header className="m7">
      <div className="m7_container">
        <h1 className="m7_brand c4">
          <Link to="/" rel="home" title="For Crew Only"><Logo/></Link>
        </h1>
        <Nav styleClass={"m7_main_navigation c5"} itemClass={"c5_link"}/>
        <div className="m7_links c5 --show-sm">
          <a className="c5_link -shopping-bag" href="/cart">
            <img src={cartIcon} alt="Shopping bag icon"/>
          </a>
          <span className="c5_link -search">
        <img src={searchIcon} alt="Search icon" id={'search-trigger'} onClick={(e) => this.toggleSearchForm(e)}/>
        {theSearch}
      </span>
          <Greeting isLoggedIn={this.isLoggedIn()} account={this.state.account} />
          <Menu isLoggedIn={this.isLoggedIn()}/>
        </div>
      </div>
    </header>
  }
}

export default Header
