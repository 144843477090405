import React from 'react';
import Logo from "./logo";
import Instagram from "./instagram";
import Facebook from "./facebook";
import Linkedin from "./linkedin";

function M6(){
    return <section className="m6">
        <div className="m6_columns container">
            <div className="m6_column">
                <h2 className="headline-3 c4">
                    <Logo/> For Crew Only</h2>
                <p className="smaller-text">
                    <a className="regular-link" href="/terms-conditions">Terms and Conditions </a> |
                    <a className="regular-link" href="/return-policy"> Return Policy </a> |
                    <a className="regular-link" href="/privacy-policy"> Privacy Policy </a>
                    <br/>© 2020 CREW ONLY LLC
                </p>
            </div>
            <div className="m6_column text-center">
                <h2 className="headline-3">Contact us</h2>
                <p className="smaller-text">
                    <a className="regular-link" href="tel:+1 (786) 478-6394">(786) 478-6394</a><br/>
                    <a className="regular-link" href="mailto: hello@forcrewonly.com">hello@forcrewonly.com</a></p>
            </div>
            <div className="m6_column --variant-1">
                <div className="m6_column_wrapper">
                    <h2 className="headline-3">Follow us</h2>
                    <p className="smaller-text">
                        <a rel="noopener noreferrer" className="sn-link" href="https://linkedin.com/forcrewonly" title="For Crew Only on LinkedIn" target="_blank"><Linkedin/></a>
                        <a rel="noopener noreferrer" className="sn-link" href="https://www.facebook.com/ForCrewOnly" title="For Crew Only on Facebook" target="_blank"><Facebook/></a>
                        <a rel="noopener noreferrer" className="sn-link" href="https://www.instagram.com/forcrewonly/" title="For Crew Only on Instagram" target="_blank"><Instagram/></a>
                    </p>
                </div>
            </div>
        </div>
    </section>
}

export default M6;
