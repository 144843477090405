import React from "react"
import hambIcon from "../assets/images/hamb_menu.svg";
import Nav from "./shared/nav";

function Menu(props) {

  const secNav = function () {
    if ( props.isLoggedIn ){
      return <nav className="m7_burger_navigation --separator" role="navigation">
        <a className="m7_burger_navigation_item" href="/my-account" title="For Crew Only Login">My Account</a>
        <a className="m7_burger_navigation_item" href="/logout" title="For Crew Only Sign-Up">Logout</a>

      </nav>;
    } else {
      return <nav className="m7_burger_navigation --separator" role="navigation">
        <a className="m7_burger_navigation_item" href="/login" title="For Crew Only Login">Login</a>
        <a className="m7_burger_navigation_item" href="/sign-up" title="For Crew Only Sign-Up">Sign-up</a>
      </nav>
    }
  }();

  return <div className="dropleft">
    <a className="c5_link -menu-hamburger" href="/menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenu2">
      <img src={hambIcon} alt="Main menu icon"/>
    </a>
    <div className="dropdown-menu animated fadeIn" aria-labelledby="dropdownMenu2">
      <Nav styleClass={"m7_burger_navigation"} itemClass={"m7_burger_navigation_item"} />
      <nav className="m7_burger_navigation" role="navigation">
        <a className="m7_burger_navigation_item" href="/about" title="About For Crew Only">About</a>
        <a className="m7_burger_navigation_item" href="/faq" title="For Crew Only FAQ">FAQ</a>
      </nav>
      {secNav}
    </div>
  </div>
}

export default Menu;
