import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Alert from "./alert";

const Layout = ({ children }) => {
  return (
    <>
        <Header />
        <Alert/>
        {children}
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
