import React from "react"
import searchIcon from "../../assets/images/search_icon.svg";

function Search() {
    // Import result is the URL of your image
    return <form action={'/index.php?route=product/search'} method={'get'}>
        <span className="caret"/>
        <div className={'input-group'}>
            <input type={'text'} name={'search'} placeholder={'Product'} className={'form-control'} />
            <div className={'input-group-append'}>
                <button className={'button-primary'} type={'submit'} id={'button-addon2'}><img className={'search-icon'} alt={'Search'} src={searchIcon}/></button>
            </div>
        </div>
        <input type={'hidden'} name={'route'} value={'product/search'}/>
        <input type={'hidden'} name={'sub_category'} value={'true'}/>
    </form>
}
export default Search
