import React from "react"

function Greeting(props) {
  if ( props.isLoggedIn === true ){
    return <div>
      <a className="c5_link --hidden-sm text-capitalize" href="/my-account">Hello, {props.account.firstname}</a>
      <a className="c5_button  --hidden-sm" href="/logout">Logout</a>
    </div>;
  } else {
    return <div>
      <a className="c5_link --hidden-sm" href="/login">Login</a>
      <a className="c5_button  --hidden-sm" href="/sign-up">Sign up</a>
    </div>;
  }
}

export default Greeting;
