import React from "react"

function Alert() {

  /*return <div className="c8 alert-warning mb-0" role="alert">
    <div className="container text-center">
      Delivery to the port of Barcelona suspended due to port restrictions.
    </div>
  </div>*/
}

export default Alert
