import React from "react"
import PropTypes from "prop-types";

function Nav(props) {
    // Import result is the URL of your image
    return <nav className={props.styleClass} role="navigation">
        <a className={props.itemClass} href="/shop" title="For Crew Only Shop">Shop</a>
        <a className={props.itemClass} href="/package-delivery" title="For Crew Only Delivery">Delivery</a>
        <a className={props.itemClass} href="/shipping" title="For Crew Only Shipping">Shipping</a>
        <a className={props.itemClass} href="/request" title="For Crew Only Request">Request</a>
    </nav>
}

Nav.propTypes = {
    styleClass: PropTypes.string,
    itemClass: PropTypes.string
};

Nav.defaultProps = {
    styleClass: ``,
    itemClass: ``,
};

export default Nav
