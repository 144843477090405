import React from "react";
import {Helmet} from "react-helmet/es/Helmet";

function Zopim() {
    return <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: 'window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=\n' +
                        'd.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.\n' +
                        '_.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");\n' +
                        '$.src="https://v2.zopim.com/?4qfg0j56VWt3txOkMNYgh8c5vWIrSlDj";z.t=+new Date;$.\n' +
                        'type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");\n'
                }]}
            />
}

export default Zopim
